import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid/Grid";

const Placeholder = ({type}) => (
          <Grid item key={`${type}_placeholder`}>
            <Typography variant="subtitle1" gutterBottom>
              Loading {type}...
            </Typography>
          </Grid>);

Placeholder.propTypes = {
  type: PropTypes.string.isRequired,
};

export default Placeholder;