import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    display: 'flex'
  }
});

function DeleteCollectionDialog(props) {
  const {
    selectedCollection: { name: collectionName, id: collectionId },
    isOpen,
    handleClose,
    handleDelete
  } = props;

  const [valid, setValid] = useState(false);
  const [name, setName] = useState('');
  const classes = useStyles();

  const handleChange = name => event => {
    const { value } = event.target;
    setName(value);
    setValid(value === collectionName);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Delete collection</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <span style={{ color: 'red' }}>
            Delete collection will completely remove all collection data. This
            operation is irreversible. Type the collection name{' '}
            <b>{collectionName}</b> to proceed.
          </span>
        </DialogContentText>
        <Grid container spacing={1} direction={'column'}>
          <Grid item>
            <TextField
              autoFocus={true}
              id="outlined-multiline-flexible"
              label="Name"
              fullWidth={true}
              value={name}
              margin="normal"
              variant="outlined"
              onChange={handleChange('name')}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} size="small" className={classes.button}>
          Cancel
        </Button>
        <Button
          disabled={!valid}
          variant="contained"
          size="small"
          className={classes.button}
          onClick={() => handleDelete(collectionId)}
          color={'secondary'}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DeleteCollectionDialog.propTypes = {
  selectedCollection: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired
};

export default DeleteCollectionDialog;
