import React from 'react';
import PropTypes from "prop-types";

import Drawer from '@material-ui/core/Drawer';

import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';

function DebugConsole(props) {

  const {collection, active, handleClose, handleChange} = props;

  return (<Drawer anchor="left"
                  open={active}
                  onClose={handleClose}>
            <JSONInput id='a_unique_id'
                       locale={locale}
                       placeholder={collection}
                       onChange={handleChange}
                       height={'auto'}/>
          </Drawer>
  );
}

DebugConsole.propTypes = {
  active: PropTypes.bool.isRequired,
  collection: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default DebugConsole;


