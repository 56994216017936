import React from 'react';

import {withStyles} from '@material-ui/core/styles';
import PropTypes from "prop-types";

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    width: theme.spacing(12),
    height: theme.spacing(3),
    border: '1px solid black'
  },
});

/**
 * Radius token preview
 * Use TokenPreview to autodetect type
 * @param props
 * @returns {*}
 * @constructor
 */
function RadiusPreview(props) {
  const {classes, value} = props;
  return (<div className={classes.root} style={{borderRadius: value}}/>);
}

RadiusPreview.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.number
};

export default withStyles(styles)(RadiusPreview);