import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { withFirebase } from "../../firebase";

import * as ROUTES from "../../constants/routes";

const AuthStateHandler = ({ history, firebase }) => {
  useEffect(
    () =>
      firebase.auth.onAuthStateChanged(user => {
        //history.push(user ? ROUTES.DASHBOARD : ROUTES.LOGIN);
      }),
    []
  );

  return null;
};
export default withFirebase(withRouter(AuthStateHandler));
