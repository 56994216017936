import React from 'react';
import PropTypes from 'prop-types';
import { getSubtypes } from '../../../utils/utils';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: 0
  },
  radioRow: {
    flexDirection: 'row'
  }
}));

const SubtypeSelector = ({ type, subtype, handleChange }) => {
  const classes = useStyles();
  const subtypes = getSubtypes(type);
  return subtypes ? (
    <>
      <FormControl component="fieldset" className={classes.formControl}>
        <RadioGroup
          aria-label="subtype"
          name="subtype"
          value={subtype}
          onChange={handleChange('subtype')}
          className={classes.radioRow}
        >
          {subtypes.map(({ type, name }, index) => (
            <FormControlLabel
              key={`${type}_${index}`}
              value={type}
              control={<Radio />}
              label={name}
            />
          ))}
          <FormControlLabel
            key={'no-subtype'}
            value={''}
            control={<Radio />}
            label={'No subtype'}
          />
        </RadioGroup>
      </FormControl>
    </>
  ) : null;
};

SubtypeSelector.propTypes = {
  type: PropTypes.string.isRequired,
  subtype: PropTypes.string,
  handleChange: PropTypes.func.isRequired
};

export default SubtypeSelector;
