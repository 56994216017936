import React from 'react';

import PropTypes from "prop-types";

/**
 * Reference token preview
 * Use TokenPreview to autodetect type
 * @param props
 * @returns {*}
 * @constructor
 */
const ReferencePreview = ({ value }) => <span><b>Reference to </b><br /><i>{value}</i><br /></span>;

ReferencePreview.propTypes = {
  value: PropTypes.any
};

export default ReferencePreview;