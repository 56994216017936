import React, {useEffect, useState} from 'react';
import {Route} from 'react-router-dom';
import {withFirebase} from "../../firebase";

const ProtectedRoute = ({component: Component, firebase, ...rest}) => {
  const [user, setUser] = useState(null);
  useEffect(() => firebase.auth.onAuthStateChanged((user) => setUser(user)));
  return <Route {...rest} render={props => user ? <Component {...props} /> : null}/>;
};

export default withFirebase(ProtectedRoute);