import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid/Grid';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

/**
 * Slider picker
 * Rounds the value to nearest integer
 * Use TokenValuePicker autodetect type
 * @param value
 * @param onChange
 * @param classes
 * @param minValue
 * @param maxValue
 * @param unit
 * @param label
 * @returns {*}
 * @constructor
 */
const SliderPicker = ({
  value,
  onChange,
  classes,
  minValue,
  maxValue,
  unit,
  label
}) => {
  const handleSliderChange = (event, value) => onChange(Math.ceil(value));
  const handleChange = event => {
    const { value } = event.target;
    onChange(value ? Math.ceil(value) : '');
  };
  return (
    <div className={classes.root}>
      <Grid container spacing={2} direction={'row'}>
        <Grid
          item
          xs={2}
          className={classNames(classes.title, classes.rowComponent)}
        >
          <Typography id="label">{label}</Typography>
        </Grid>
        <Grid
          item
          xs={6}
          className={classNames(classes.slider, classes.rowComponent)}
        >
          <Slider
            value={value}
            aria-labelledby="label"
            onChange={handleSliderChange}
            min={minValue}
            max={maxValue}
          />
        </Grid>
        <Grid item xs={4}>
          <ValidatorForm onSubmit={() => {}}>
            <TextValidator
              validators={[`minNumber:${minValue}`, `maxNumber:${maxValue}`]}
              errorMessages={['Invalid value', 'Invalid value']}
              value={value}
              onChange={handleChange}
              type="number"
              margin="normal"
              variant="outlined"
              min={minValue}
              max={maxValue}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">{unit}</InputAdornment>
                )
              }}
              className={classNames(classes.value, classes.rowComponent)}
              step="1"
            />
          </ValidatorForm>
        </Grid>
      </Grid>
    </div>
  );
};

SliderPicker.propTypes = {
  label: PropTypes.string.isRequired,
  unit: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  minValue: PropTypes.number,
  onChange: PropTypes.func.isRequired
};

SliderPicker.defaultProps = {
  minValue: 0,
  maxValue: 100,
  unit: ''
};

const styles = theme => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.grey[200],
    padding: theme.spacing(2)
  },
  rowComponent: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex'
  },
  title: {},
  slider: {
    padding: '22px 0px'
  },
  value: {}
});

export default withStyles(styles)(SliderPicker);
