import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography/Typography';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import { withStyles } from '@material-ui/core/styles';
import { withFirebase } from '../../firebase';
import Collection from '../../components/Collection/Collection';
import Placeholder from '../../components/Placeholder/Placeholder';
import NewCollectionDialog from './NewCollectionDialog';
import DeleteCollectionDialog from './DeleteCollectionDialog';
import RenameCollectionDialog from './RenameCollectionDialog';

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  fab: {
    bottom: theme.spacing(12),
    right: theme.spacing(2),
    position: 'fixed'
  },
  paper: {
    minHeight: '100vh',
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    backgroundColor: '#F5F8F9'
  },
  mainTitle: {
    color: theme.palette.text.primary,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2)
  }
});

export function DashBoard(props) {
  const { classes, firebase } = props;
  const [workspaces, setWorkspaces] = useState([]);
  const [selectedCollection, setselectedCollection] = useState({});
  const [isLoading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [isNewCollectionDialogOpen, setNewCollectionDialogOpen] = useState(
    false
  );
  const [
    isDeleteCollectionDialogOpen,
    setDeleteCollectionDialogOpen
  ] = useState(false);
  const [isEditCollectionDialogOpen, setEditCollectionDialogOpen] = useState(
    false
  );

  const user = firebase.auth.currentUser;

  const toggleNewCollectionDialog = () =>
    setNewCollectionDialogOpen(!isNewCollectionDialogOpen);

  const toggleDeleteCollectionDialog = () =>
    setDeleteCollectionDialogOpen(!isDeleteCollectionDialogOpen);

  const toggleEditCollectionDialog = () =>
    setEditCollectionDialogOpen(!isEditCollectionDialogOpen);

  const createCollection = collection => {
    const user = firebase.auth.currentUser;
    const document = firebase.db
      .collection('users')
      .doc(user.uid)
      .collection('workspaces')
      .doc();

    document
      .set({
        id: document.id,
        ...collection
      })
      .then(function() {
        enqueueSnackbar('Workspace successfully created', {
          variant: 'success'
        });
        history.push(`/edit/${document.id}`);
      })
      .catch(function(error) {
        enqueueSnackbar('Failed to create workspace', { variant: 'error' });
      });
  };

  const handleCreateCollection = collection => {
    toggleNewCollectionDialog();
    createCollection(collection);
  };

  const handleDeleteCollection = id => {
    setselectedCollection(workspaces.find(workspace => workspace.id === id));
    toggleDeleteCollectionDialog();
  };

  const handleEditCollection = id => {
    setselectedCollection(workspaces.find(workspace => workspace.id === id));
    toggleEditCollectionDialog();
  };

  const handleConfirmDeleteCollection = async id => {
    toggleDeleteCollectionDialog();

    try {
      await firebase.db
        .collection('users')
        .doc(user.uid)
        .collection('workspaces')
        .doc(id)
        .delete();

      enqueueSnackbar('Workspace successfully deleted', {
        variant: 'success'
      });
    } catch {
      enqueueSnackbar('Workspace delete failed', {
        variant: 'error'
      });
    }
  };

  const handleConfirmRenameCollection = async ({ id, name, description }) => {
    toggleEditCollectionDialog();

    try {
      await firebase.db
        .collection('users')
        .doc(user.uid)
        .collection('workspaces')
        .doc(id)
        .update({ name, description });

      enqueueSnackbar('Workspace successfully saved', {
        variant: 'success'
      });
    } catch {
      enqueueSnackbar('Workspace edit failed', {
        variant: 'error'
      });
    }
  };

  useEffect(() => {
    const subsription = firebase.db
      .collection('users')
      .doc(user.uid)
      .collection('workspaces')
      .onSnapshot(function(querySnapshot) {
        const remoteWorkspaces = querySnapshot.docs.map(doc => {
          const { name, description, tokens, version } = doc.data();
          return { name, description, id: doc.id, tokens, version };
        });
        setLoading(false);
        setWorkspaces(remoteWorkspaces);
      });

    return () => {
      // Unsubscribe the database listener
      subsription();
    };
  }, []);

  const renderCollection = collection => {
    return !collection || collection.length === 0 ? (
      <Typography variant="subtitle1" gutterBottom>
        Create your first collection
      </Typography>
    ) : (
      collection.map((item, index) => (
        <Grid xs={12} sm={6} md={4} lg={3} item key={'collection_' + index}>
          <Collection
            collection={item}
            handleClone={() => {}}
            handleDelete={handleDeleteCollection}
            handleEditDetails={handleEditCollection}
          />
        </Grid>
      ))
    );
  };

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Grid container justify="center">
            <Grid item xs={10}>
              <Typography
                variant="h4"
                component="h1"
                gutterBottom
                className={classes.mainTitle}
              >
                Collections
              </Typography>

              <Grid container spacing={3}>
                {isLoading ? (
                  <Placeholder type={'collections'} />
                ) : (
                  renderCollection(workspaces)
                )}
              </Grid>
              <Grid container spacing={2} justify="flex-end">
                <Fab
                  color="primary"
                  aria-label="New collection"
                  title="New collection"
                  className={classes.fab}
                  onClick={toggleNewCollectionDialog}
                >
                  <AddIcon />
                </Fab>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </div>
      <NewCollectionDialog
        isOpen={isNewCollectionDialogOpen}
        handleSave={handleCreateCollection}
        handleClose={toggleNewCollectionDialog}
      />
      {isDeleteCollectionDialogOpen && (
        <DeleteCollectionDialog
          selectedCollection={selectedCollection}
          isOpen={isDeleteCollectionDialogOpen}
          handleDelete={handleConfirmDeleteCollection}
          handleClose={toggleDeleteCollectionDialog}
        />
      )}
      {isEditCollectionDialogOpen && (
        <RenameCollectionDialog
          collection={selectedCollection}
          isOpen={isEditCollectionDialogOpen}
          handleSave={handleConfirmRenameCollection}
          handleClose={toggleEditCollectionDialog}
        />
      )}
    </React.Fragment>
  );
}

export default compose(withFirebase, withStyles(styles))(DashBoard);
