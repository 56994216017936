import React from 'react';
import PropTypes from 'prop-types';

import { colorObjectToCss, colorObjectToHex } from '../../utils/utils';
/**
 * Default token value preview
 * @param props
 * @returns {*}
 * @constructor
 */
const ColorValuePreview = ({ value }) => (
  <span>
    {colorObjectToHex(value)} <br /> {colorObjectToCss(value)}
  </span>
);

ColorValuePreview.propTypes = {
  value: PropTypes.any.isRequired
};

export default ColorValuePreview;
