import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import MaterialTable from 'material-table';
import Paper from '@material-ui/core/Paper';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import TokenPreview from '../TokenPreview/TokenPreview';
import DefaultPreview from '../TokenPreview/DefaultPreview';
import ColorValuePreview from '../TokenPreview/ColorValuePreview';
import ReferencePreview from '../TokenPreview/ReferencePreview';
import {
  colorObjectToCss,
  colorObjectToHex,
  getTokenFromCurrentCollection,
  getTokenFullName,
  getTokenNamePrefix,
  isReferenceToken
} from '../../utils/utils';

const useStyles = makeStyles({
  root: {
    width: '100%',
    overflowX: 'auto'
  }
});

function TokenList(props) {
  const { tokens, handleTokenEdit, handleTokenDelete } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorId, setAnchorId] = React.useState(null);
  const [tableData, setTableData] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    // _ prefix for material-table internal use
    setTableData(
      tokens.map(token => ({
        ...token,
        _tableType: getTokenNamePrefix(token.type, token.subtype).slice(0, -1),
        _tableNameDescription: `${token.name} ${token.description}`,
        _tableValue: `${token.value} ${
          token.type === 'color'
            ? colorObjectToHex(token.value) +
              ' ' +
              colorObjectToCss(token.value)
            : ''
        }`
      }))
    );
  }, [tokens]);

  if (!Array.isArray(tokens)) {
    return null;
  }

  const handleTokenMenuClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setAnchorId(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const dataColumns = [
    {
      title: 'Type',
      field: 'type',
      defaultSort: 'asc',
      defaultGroupOrder: 0
    },
    {
      title: 'Subtype',
      field: 'subtype',
      defaultSort: 'asc'
    },
    {
      title: 'Name & Description',
      field: '_tableNameDescription',
      grouping: false,
      searchable: true,
      render: row => (
        <span>
          <b>{getTokenFullName(row)}</b>
          <br />
          {row.description}
        </span>
      )
    },
    {
      title: 'Value',
      field: '_tableValue',
      grouping: false,
      searchable: true,
      render: row => {
        if (isReferenceToken(row)) {
          const referencedToken = getTokenFromCurrentCollection(
            row.reference,
            tokens
          );
          return <ReferencePreview value={getTokenFullName(referencedToken)} />;
        }
        switch (row.type) {
          case 'color':
            return <ColorValuePreview value={row.value} />;
          default:
            return <DefaultPreview value={row.value} />;
        }
      }
    },
    {
      title: 'Preview',
      field: 'value',
      grouping: false,
      searchable: false,
      render: row => <TokenPreview token={row} />
    },
    {
      title: 'Published',
      field: 'releaseVersion',
      grouping: false,
      searchable: true,
      render: row => (
        <React.Fragment>
          {row.releaseVersion ? (
            <Typography variant="overline">{row.releaseVersion}</Typography>
          ) : (
            <Tooltip title="Not Yet Published" aria-label="Not yet published">
              <Typography variant="overline">n/a</Typography>
            </Tooltip>
          )}
        </React.Fragment>
      )
    }
  ];
  if (handleTokenEdit && handleTokenDelete) {
    dataColumns.push({
      grouping: false,
      render: row => {
        return (
          <>
            <IconButton onClick={e => handleTokenMenuClick(e, row.id)}>
              <MoreVertIcon id="action-menu" />
            </IconButton>

            {anchorId === row.id && (
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    handleClose();
                    handleTokenEdit(row.id);
                  }}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    handleTokenDelete(row);
                  }}
                >
                  Delete
                </MenuItem>
              </Menu>
            )}
          </>
        );
      }
    });
  }

  return (
    <Paper className={classes.root}>
      <MaterialTable
        title={'Tokens'}
        options={{ paging: false, pageSize: 100, grouping: true }}
        columns={dataColumns}
        data={tableData}
        components={{
          Groupbar: () => <div>&nbsp;</div>
        }}
      />
    </Paper>
  );
}

TokenList.propTypes = {
  tokens: PropTypes.array.isRequired,
  handleTokenEdit: PropTypes.func,
  handleTokenDelete: PropTypes.func
};

export default TokenList;
