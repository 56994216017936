import React from 'react';

import {withStyles} from '@material-ui/core/styles';
import PropTypes from "prop-types";

import {opacityValueToCss} from '../../utils/utils';

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.common.black,
    width: theme.spacing(12),
    height: theme.spacing(3),
    border: '1px solid black'
  },
});

/**
 * Opacity token preview
 * Use TokenPreview to autodetect type
 * @param props
 * @returns {*}
 * @constructor
 */
function OpacityPreview(props) {
  const {classes, value} = props;
  return (<div className={classes.root} style={{opacity: opacityValueToCss(value)}}/>);
}

OpacityPreview.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default withStyles(styles)(OpacityPreview);