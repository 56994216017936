import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import TokenValuePicker from './TokenValuePicker';
import ReferencePicker from './ReferencePicker';
import { getAvailableReferences, isReferenceToken } from '../../utils/utils';

/**
 * Render reference / value picker for token
 * @param type
 * @param onChange
 * @param value
 * @returns {*}
 * @constructor
 */
const TokenPicker = ({ token, tokens, onChange, classes }) => {
  const onValueChange = value => onChange({ value, reference: null });
  const onReferenceChange = ({ value, reference }) =>
    onChange({ value, reference });

  const { value, type } = token;
  const availableTokens = getAvailableReferences(token, tokens);
  const [showReference, setShowReference] = useState(isReferenceToken(token));
  const referencePicker = (
    <ReferencePicker
      reference={token.reference}
      items={availableTokens}
      onChange={onReferenceChange}
    />
  );
  const tokenPicker = (
    <TokenValuePicker type={type} value={value} onChange={onValueChange} />
  );

  return (
    <React.Fragment>
      <FormControl component="fieldset" className={classes.formControl}>
        <RadioGroup
          aria-label="Value type"
          required={true}
          name="valueType"
          className={classes.group}
          value={showReference.toString()}
          onChange={() => setShowReference(!showReference)}
        >
          <FormControlLabel
            value={'false'}
            control={<Radio />}
            label="New value"
          />
          <FormControlLabel
            disabled={availableTokens.length === 0}
            value={'true'}
            control={<Radio />}
            label="Reference to an existing token"
          />
        </RadioGroup>
      </FormControl>
      <br />
      {showReference ? referencePicker : tokenPicker}
    </React.Fragment>
  );
};

const styles = theme => ({
  root: {
    display: 'flex'
  },
  group: {
    margin: `${theme.spacing(1)}px 0`,
    flexDirection: 'row'
  }
});

TokenPicker.propTypes = {
  token: PropTypes.object.isRequired,
  tokens: PropTypes.array.isRequired,
  onChange: PropTypes.func
};

export default withStyles(styles)(TokenPicker);
