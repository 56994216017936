import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    display: 'flex',
    padding: theme.spacing(2)
  },
  descSpace: {
    marginBottom: theme.spacing(2)
  },
  futureFeat: {
    color: '#9a0036'
  }
});

const UserTab = ({ classes }) => (
  <Card className={classes.root}>
    <CardContent>
      <Typography variant="h5" component="h3" gutterBottom>
        Users
      </Typography>
      <Typography variant="body1" gutterBottom className={classes.descSpace}>
        A list of users who have access to this collection.
      </Typography>

      <Typography variant="body2" gutterBottom className={classes.futureFeat}>
        This feature is coming later this year.
      </Typography>
    </CardContent>
  </Card>
);

export default withStyles(styles)(UserTab);
