import React from 'react';

const Context = React.createContext(null);
export const withFirebase = Component => props => (
  <Context.Consumer>
    {firebase => <Component {...props} firebase={firebase} />}
  </Context.Consumer>
);

export default Context;
