import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Privacy from '../Privacy/Privacy';
import {withStyles} from '@material-ui/core/styles';

const styles = () => ({
  root: {
    display: 'flex',
  }
});


function PrivacyDialog(props) {
  const {isOpen, handleClose} = props;

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        scroll='paper'
        aria-labelledby="scroll-dialog-title"
      >
        <DialogTitle id="scroll-dialog-title">Privacy policy</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText>
            <Privacy />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

PrivacyDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
export default withStyles(styles)(PrivacyDialog);