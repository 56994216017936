import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import CardActions from '@material-ui/core/CardActions';
import VersionBadge from '../VersionBadge/VersionBadge';
import CollectionMenu from './CollectionMenu';

const styles = theme => ({
  card: {
    position: 'relative'
  },
  content: {
    paddingBottom: 0
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 18,
    marginBottom: theme.spacing(1)
  },
  pos: {
    marginBottom: theme.spacing(3),
    height: 45,
    maxHeight: 45,
    overflow: 'hidden'
  },
  infoSection: {
    width: '50%',
    marginTop: 12,
    marginBottom: 12,
    lineHeight: '16px',
    display: 'inline-block'
  },
  editLink: {
    fontWeight: 500,
    padding: '4px 5px',
    textTransform: 'uppercase',
    textDecoration: 'none',
    color: theme.palette.text.primary,
    '&:hover': {
      borderRadius: 3,
      backgroundColor: theme.palette.grey[200]
    }
  },
  infoTitle: {
    display: 'block',
    color: theme.palette.text.primary
  },
  infoLabel: {
    fontSize: 12,
    textTransform: 'uppercase'
  },
  divider: {
    marginLeft: -theme.spacing(2),
    marginRight: -theme.spacing(2)
  }
});

function SimpleCard(props) {
  const {
    classes,
    collection,
    handleClone,
    handleDelete,
    handleEditDetails
  } = props;
  const { name, description, id, tokens, version } = collection;
  const amountOfTokens = tokens.length || 0;

  const _handleClone = () => handleClone(id);
  const _handleDelete = () => handleDelete(id);
  const _handleEditDetails = () => handleEditDetails(id);

  return (
    <Card className={classes.card}>
      <CardContent className={classes.content}>
        <Typography
          id={'card-title'}
          variant="h5"
          component="h2"
          className={classes.title}
        >
          {name}
        </Typography>
        <Typography
          id={'card-description'}
          className={classes.pos}
          color="textSecondary"
          title={description}
        >
          {description}
        </Typography>
        <CollectionMenu
          handleClone={_handleClone}
          handleDelete={_handleDelete}
          handleEditDetails={_handleEditDetails}
          id={id}
        />
        <Divider className={classes.divider} />
        <Typography
          id={'card-info'}
          className={classes.infoSection}
          color="textSecondary"
        >
          <span className={classes.infoTitle}>{amountOfTokens}</span>
          <span className={classes.infoLabel}>
            {amountOfTokens === 1 ? ' token' : ' tokens'}
          </span>
        </Typography>
        <Typography
          id={'card-version'}
          className={classes.infoSection}
          color="textSecondary"
        >
          <VersionBadge version={version} />
          <br />
          <span className={classes.infoLabel}>version</span>
        </Typography>
        <Divider className={classes.divider} />
      </CardContent>
      <CardActions>
        <Link to={`edit/${id}`} className={classes.editLink}>
          Edit collection
        </Link>
      </CardActions>
    </Card>
  );
}

SimpleCard.propTypes = {
  classes: PropTypes.object.isRequired,
  collection: PropTypes.object.isRequired,
  handleClone: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleEditDetails: PropTypes.func.isRequired
};

export default withStyles(styles)(SimpleCard);
