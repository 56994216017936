import React from 'react';
import PropTypes from 'prop-types';

import ColorPreview from "./ColorPreview";
import OpacityPreview from "./OpacityPreview";
import RadiusPreview from "./RadiusPreview";
import LineHeightPreview from "./LineHeightPreview";
import DefaultPreview from "./DefaultPreview";
import {getTokenFromCurrentCollection, isReferenceToken} from "../../utils/utils";

/**
 * Render preview component for token based on type
 * Render linked value if token is reference token
 * @param props
 * @returns {*}
 * @constructor
 */
const TokenPreview = (props) => {
  let {type, value, reference} = props.token;
  let referencePreview, previewValue = value;

  if(isReferenceToken({reference})) {
    const referenceToken = getTokenFromCurrentCollection(reference);
    referencePreview = null; //<ReferencePreview key={reference} value={getTokenFullName(referenceToken)}/>;
    previewValue = referenceToken.value;
  }

  let tokenPreview = null;

  switch (type) {
    case 'color':
      tokenPreview =  <ColorPreview key={previewValue} value={previewValue}/>;
      break;
    case 'opacity':
      tokenPreview =  <OpacityPreview key={previewValue} value={previewValue}/>;
      break;
    case 'radius':
      tokenPreview = <RadiusPreview key={previewValue} value={previewValue}/>;
      break;
    case 'height':
      tokenPreview = <LineHeightPreview key={previewValue} value={previewValue}/>;
      break;
    default:
      tokenPreview =  <DefaultPreview key={previewValue} value={previewValue}/>;
  }

  return [referencePreview, tokenPreview];
};

TokenPreview.propTypes = {
  token: PropTypes.object.isRequired
};

export default TokenPreview;