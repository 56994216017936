import React from 'react';

import {withStyles} from '@material-ui/core/styles';
import PropTypes from "prop-types";

import {colorObjectToCss} from '../../utils/utils';

const styles = (theme) => ({
  root: {
    width: theme.spacing(12),
    height: theme.spacing(3),
  },
});

/**
 * Color token preview
 * Use TokenPreview to autodetect type
 * @param props
 * @returns {*}
 * @constructor
 */
function ColorPreview(props) {
  const {classes, value} = props;
  return (<div className={classes.root} style={{backgroundColor: value ? colorObjectToCss(value) : undefined}}/>);
}

ColorPreview.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.object
};

export default withStyles(styles)(ColorPreview);