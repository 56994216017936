import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';

import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    padding: theme.spacing(2)
  },
  descSpace: {
    marginBottom: theme.spacing(2)
  },
  installSpace: {
    marginBottom: theme.spacing(3)
  },
  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  topSpace: {
    marginTop: theme.spacing(2)
  },
  codehighlight: {
    background: '#eaeaea',
    padding: theme.spacing(1, 2),
    whiteSpace: 'pre-line',
    fontSize: 12
  },
  steptitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginTop: theme.spacing(3)
  }
}));

const GLOBAL_READ_TOKEN = '9c32c81b52698253b51ec44655aa209ad7f83ac9ed39d495';

const InstallationTab = ({ version, packageId }) => {
  const classes = useStyles();

  const FinalStep = () => (
    <>
      <Typography variant="body1" gutterBottom>
        Use tokens from NPM package (react example). <br />
        Replace <b>colorBackgroundDefault</b> with one of your own tokens
      </Typography>
      <Typography
        variant="body1"
        className={classes.codehighlight}
        gutterBottom
      >
        <pre>
          <code>
            {`import React from 'react';
import tokens from '@designtokens/${packageId}';

const divStyle = {
  color: tokens.colorBackgroundDefault.value
};

function TokenExample() {
  return <span style={divStyle}>Hello Tokens!</div> 
};
`}
          </code>
        </pre>
      </Typography>
    </>
  );

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="h4" component="h3" gutterBottom>
          Installation
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.descSpace}>
          Install a collection via NPM or Yarn by following instructions
          depending on your use case.
        </Typography>
        {!version && (
          <Alert severity="warning">
            Collection status is unreleased. Create a release before installing.
          </Alert>
        )}
        <Divider className={classes.divider} />
        <Typography variant="h6" component="h6" gutterBottom>
          User specific configuration
        </Typography>
        <Typography variant="body1" gutterBottom>
          Suitable for single user environments
        </Typography>
        <Typography
          variant="subtitle2"
          className={classes.steptitle}
          gutterBottom
        >
          Step 1
        </Typography>
        <Typography variant="body1" gutterBottom>
          Add designtokens.dev repository as a source for packages with
          @designtokes scope.
        </Typography>
        <Typography
          variant="body1"
          className={classes.codehighlight}
          gutterBottom
        >
          <pre>
            <code>
              {`npm config set @designtokens:registry https://packagecloud.io/designtokens/collections/npm/ \nnpm config set //packagecloud.io/designtokens/collections/npm/:_authToken=${GLOBAL_READ_TOKEN}`}
            </code>
          </pre>
        </Typography>
        <Typography
          variant="subtitle2"
          className={classes.steptitle}
          gutterBottom
        >
          Step 2
        </Typography>
        <Typography variant="body1" gutterBottom>
          Install package to project
        </Typography>
        <Typography
          variant="body1"
          className={classes.codehighlight}
          gutterBottom
        >
          <code id={'installCommand'}>
            {`npm install @designtokens/${packageId}@${version ||
              '0.0.1'} --save`}
          </code>
        </Typography>
        <Typography
          variant="subtitle2"
          className={classes.steptitle}
          gutterBottom
        >
          Step 3
        </Typography>
        <FinalStep />
        <Divider className={classes.divider} />
        <Typography variant="h6" component="h6" gutterBottom>
          Project specific configurations
        </Typography>
        <Typography variant="body1" gutterBottom>
          Suitable for single and multi user environments
        </Typography>
        <Typography
          variant="subtitle2"
          className={classes.steptitle}
          gutterBottom
        >
          Step 1
        </Typography>
        <Typography variant="body1" gutterBottom>
          Create a project specific .npmrc file to project root with entry
        </Typography>
        <Typography
          variant="body1"
          className={classes.codehighlight}
          gutterBottom
        >
          <pre>
            <code>
              {`@designtokens:registry=https://packagecloud.io/designtokens/collections/npm/ \n//packagecloud.io/designtokens/collections/npm/:_authToken=${GLOBAL_READ_TOKEN}`}
            </code>
          </pre>
        </Typography>
        <Typography
          variant="subtitle2"
          className={classes.steptitle}
          gutterBottom
        >
          Step 2
        </Typography>
        <Typography variant="body1" gutterBottom>
          Install package to project
        </Typography>
        <Typography
          variant="body1"
          className={classes.codehighlight}
          gutterBottom
        >
          <code>
            {`npm install @designtokens/${packageId}@${version ||
              '0.0.1'} --save`}
          </code>
        </Typography>
        <Typography
          variant="subtitle2"
          className={classes.steptitle}
          gutterBottom
        >
          Step 3
        </Typography>
        <Typography variant="body1" gutterBottom>
          Commit .npmrc to repository
        </Typography>
        <Typography
          variant="subtitle2"
          className={classes.steptitle}
          gutterBottom
        >
          Step 4
        </Typography>
        <FinalStep />
      </CardContent>
    </Card>
  );
};

export default InstallationTab;
