import React, { useCallback, useEffect, useState } from 'react';
import { compose } from 'recompose';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button/Button';
import { useSnackbar } from 'notistack';
import Typography from '@material-ui/core/Typography/Typography';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import CollectionStore from '../../custom-hooks/CollectionStore';
import CollectionTabs from './Tabs/CollectionTabs';

import { withFirebase } from '../../firebase';
import Placeholder from '../../components/Placeholder/Placeholder';
import TokenDialog from './TokenDialog/TokenDialog';
import DebugConsole from './DebugConsole';
import Fab from '@material-ui/core/Fab/Fab';
import BugIcon from '@material-ui/icons/BugReport';
import AddIcon from '@material-ui/icons/Add';
import DeleteTokenDialog from './DeleteTokenDialog';
import {
  getAmountOfTokenReferences,
  getTokenFullName,
  isNameAvailable
} from '../../utils/utils';
import VersionInfo from '../../components/VersionInfo/VersionInfo';
import ReleaseDialog from './ReleaseDialog';

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  debugFab: {
    bottom: theme.spacing(20),
    right: theme.spacing(2),
    position: 'fixed'
  },
  tokenFab: {
    bottom: theme.spacing(12),
    right: theme.spacing(2),
    position: 'fixed'
  },
  paper: {
    minHeight: '100vh',
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    backgroundColor: '#F5F8F9'
  },
  headerArea: {
    marginBottom: theme.spacing(4)
  },
  backSection: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  back: {
    color: theme.palette.grey[600],
    textDecoration: 'none'
  },
  backIcon: {
    fontSize: '16px',
    color: theme.palette.grey[600]
  },
  backTitle: {
    marginLeft: 4,
    paddingTop: 2,
    color: theme.palette.grey[600]
  }
});

function Overview(props) {
  const { classes, firebase, match } = props;
  const { enqueueSnackbar } = useSnackbar();
  const id = match.params.slug;
  const [workspace, setWorkspace] = useState(undefined);
  const [data, setData] = CollectionStore.useContainer();
  const [selectedToken, setSelectedToken] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [isDebugConsoleOpen, setDebugConsoleOpen] = useState(false);
  const [isTokenDialogOpen, setTokenDialogOpen] = useState(false);
  const [isReleaseDialogOpen, setReleaseDialogOpen] = useState(false);
  const [isDeleteTokenDialogOpen, setDeleteTokenDialogOpen] = useState(false);
  const [isDirty, setDirty] = useState(false);

  const handleChange = name => event =>
    setData({ ...data, [name]: event.target.value });

  // Autosave on changes
  useEffect(() => {
    if (isDirty) {
      saveChanges();
      setDirty(false);
    }
  });

  const saveChanges = () =>
    workspace
      .update(data)
      .then(() => {
        enqueueSnackbar('Successfully saved', { variant: 'success' });
      })
      .catch(() => {
        enqueueSnackbar('Error updating document', { variant: 'error' });
      });

  const handleCopyToClipboard = () =>
    enqueueSnackbar(`Copied to clipboard`, {
      variant: 'success'
    });
  const toggleDebugConsole = () => setDebugConsoleOpen(!isDebugConsoleOpen);
  const handleDebugChange = object => !object.error && setData(object.jsObject);
  const toggleTokenDialog = () => setTokenDialogOpen(!isTokenDialogOpen);
  const toggleReleaseDialog = () => setReleaseDialogOpen(!isReleaseDialogOpen);
  const toggleDeleteTokenDialog = () =>
    setDeleteTokenDialogOpen(!isDeleteTokenDialogOpen);

  const openNewTokenDialog = () => {
    setSelectedToken({});
    toggleTokenDialog();
  };

  const handleTokenDialogClose = () => {
    setSelectedToken(null);
    toggleTokenDialog();
  };

  // Add of modify token to collection based on id
  const handleTokenAdd = token => {
    const index = data.tokens.findIndex(entry => token.id === entry.id);
    if (index === -1) {
      setData({ ...data, tokens: [...data.tokens, token] });
    } else {
      const modifiedTokens = [...data.tokens];
      modifiedTokens[index] = token;
      setData({ ...data, tokens: [...modifiedTokens] });
    }
    setDirty(true);
  };

  const handleTokenEdit = useCallback(
    tokenId => {
      setSelectedToken(data.tokens.find(token => token.id === tokenId));
      toggleTokenDialog();
    },
    [data]
  );

  // nameValidator need to be passed from here to token dialog as isNameAvaiable uses useContext which
  // cannot be called with useEffect used to register custom validator
  const nameValidator = token => {
    return isNameAvailable(token, data.tokens);
  };

  const handleOpenTokenDeleteDialog = useCallback(
    token => {
      setSelectedToken({
        ...token,
        amountOfReferences: getAmountOfTokenReferences(token.id, data.tokens)
      });
      toggleDeleteTokenDialog();
    },
    [data]
  );

  const handleTokenDelete = () => {
    const modifiedTokens = data.tokens.filter(
      entry => entry.id !== selectedToken.id
    );
    setData({ ...data, tokens: [...modifiedTokens] });
    setDirty(true);
    setSelectedToken(null);
    enqueueSnackbar(`Token ${getTokenFullName(selectedToken)} deleted`, {
      variant: 'success'
    });
    toggleDeleteTokenDialog();
  };

  const handleRelease = version => {
    setData({ ...data, version });
    setDirty(true);
    toggleReleaseDialog();
  };

  useEffect(() => {
    const user = firebase.auth.currentUser;
    const reference = firebase.db
      .collection('users')
      .doc(user.uid)
      .collection('workspaces')
      .doc(id);

    setWorkspace(reference);

    reference.onSnapshot(doc => {
      setLoading(false);
      setData(doc.data());
    }); /*
      .catch(() => {
        enqueueSnackbar("Error loading document", { variant: "error" });
      });*/
  }, []);
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container spacing={2} justify="center" direction={'row'}>
          {isLoading ? (
            <Placeholder type={'collection'} />
          ) : (
            <React.Fragment>
              <Grid item xs={10} className={classes.headerArea}>
                <Grid className={classes.backSection}>
                  <Link to="/dashboard" className={classes.back}>
                    <Button size="small" className={classes.backButton}>
                      <ArrowBackIosIcon className={classes.backIcon} />{' '}
                      <Typography className={classes.backTitle}>
                        All collections
                      </Typography>
                    </Button>
                  </Link>
                </Grid>
                <VersionInfo
                  data={data}
                  handleChange={handleChange}
                  handleBlur={() => setDirty(true)}
                  toggleReleaseDialog={toggleReleaseDialog}
                  handleCopyToClipboard={handleCopyToClipboard}
                />
              </Grid>
              <Grid item xs={10}>
                <CollectionTabs
                  handleTokenEdit={handleTokenEdit}
                  handleOpenTokenDeleteDialog={handleOpenTokenDeleteDialog}
                  data={data}
                />
              </Grid>
            </React.Fragment>
          )}
        </Grid>
        <Grid container spacing={2} justify="flex-end">
          {window.godmode && (
            <Fab
              color="secondary"
              aria-label="Debug console"
              title="Debug console"
              className={classes.debugFab}
              onClick={toggleDebugConsole}
              disabled={isLoading}
            >
              <BugIcon />
            </Fab>
          )}
          <Fab
            color="primary"
            aria-label="Add new"
            title="Add new"
            className={classes.tokenFab}
            onClick={openNewTokenDialog}
            disabled={isLoading}
          >
            <AddIcon />
          </Fab>
        </Grid>
      </Paper>
      <DebugConsole
        active={isDebugConsoleOpen}
        handleClose={toggleDebugConsole}
        handleChange={handleDebugChange}
        collection={data}
      />
      {selectedToken && (
        <TokenDialog
          isOpen={isTokenDialogOpen}
          handleClose={handleTokenDialogClose}
          handleSave={handleTokenAdd}
          selectedToken={selectedToken}
          tokens={data.tokens}
          nameValidator={nameValidator}
        />
      )}
      <DeleteTokenDialog
        onConfirm={handleTokenDelete}
        onClose={toggleDeleteTokenDialog}
        isOpen={isDeleteTokenDialogOpen}
        token={selectedToken}
      />
      <ReleaseDialog
        onConfirm={handleRelease}
        onClose={toggleReleaseDialog}
        isOpen={isReleaseDialogOpen}
        collection={data}
      />
    </div>
  );
}

export default compose(withRouter, withFirebase, withStyles(styles))(Overview);
