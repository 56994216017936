import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    display: 'flex'
  }
});

function NewCollectionDialog(props) {
  const { isOpen, handleClose, handleSave } = props;
  const [data, setData] = useState({});
  const classes = useStyles();

  const handleChange = name => event =>
    setData({ ...data, [name]: event.target.value });

  const onSave = () => handleSave(data);

  useEffect(() => {
    setData({ name: '', description: '', tokens: [] });
  }, []);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Create a new collection</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Collections are containers for design tokens
        </DialogContentText>
        <Grid container spacing={1} direction={'column'}>
          <Grid item>
            <TextField
              id="outlined-multiline-flexible"
              label="Name"
              fullWidth={true}
              value={data.name}
              margin="normal"
              variant="outlined"
              onChange={handleChange('name')}
            />
          </Grid>
          <Grid item>
            <TextField
              id="outlined-multiline-flexible"
              label="Description"
              fullWidth={true}
              multiline
              rowsMax="4"
              value={data.description}
              margin="normal"
              variant="outlined"
              onChange={handleChange('description')}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.button}
          onClick={onSave}
        >
          Save
        </Button>
        <Button onClick={handleClose} size="small" className={classes.button}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

NewCollectionDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired
};

export default NewCollectionDialog;
