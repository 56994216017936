import React from 'react';
import PropTypes from 'prop-types';

import {SketchPicker} from 'react-color'

/**
 * Color token value picker
 * Use TokenValuePicker autodetect type
 * @param value
 * @param onChange
 * @returns {*}
 * @constructor
 */
const ColorPicker = ({value, onChange}) => (
        <SketchPicker color={value} onChangeComplete={onChange}/>);

ColorPicker.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired
};

export default ColorPicker;