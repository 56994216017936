import colorConvert from 'color-convert';

import TokenTypes from '../constants/tokenTypes';
import CollectionStore from '../custom-hooks/CollectionStore';

/**
 * Convert color token value object to css prop
 * @param value
 * @returns {*}
 */
export const colorObjectToCss = value =>
  typeof value.r === 'number' &&
  typeof value.g === 'number' &&
  typeof value.b === 'number' &&
  typeof value.a === 'number'
    ? `rgba(${value.r},${value.g},${value.b},${value.a})`
    : undefined;

/**
 * Convert color token value object to hex css prop
 * @param value
 * @returns {*}
 */
export const colorObjectToHex = value =>
  typeof value.r === 'number' &&
  typeof value.g === 'number' &&
  typeof value.b === 'number' &&
  typeof value.a === 'number'
    ? `#${colorConvert.rgb.hex(value.r, value.g, value.b)}`
    : undefined;

/**
 * Convert opacity token value to css prop
 * @param value
 * @returns {*}
 */
export const opacityValueToCss = value => {
  if (typeof value === 'number') {
    return value === 0 ? 0 : value / 100;
  }
  return undefined;
};

/**
 * Get all subtypes for select token
 * @param type
 * @param tokenTypes
 * @returns {array|null}
 */
export const getSubtypes = (type, tokenTypes = TokenTypes) => {
  const token = tokenTypes.find(item => item.type === type);
  return token && token.subtypes ? token.subtypes : null;
};

/**
 * Return default subtype id
 * @param type
 * @param tokenTypes collection to search for
 * @returns {string|null}
 */
export const getDefaultSubtype = (type, tokenTypes = TokenTypes) => {
  const subtypes = getSubtypes(type, tokenTypes);
  if (subtypes) {
    const defaultSubtype = subtypes.find(subtype => subtype.default);
    if (defaultSubtype) {
      return defaultSubtype.type;
    }
  }
  return null;
};

export const getTokenNamePrefix = (type, subtype) =>
  `${type}-${subtype ? subtype + '-' : ''}`;
export const getTokenFullName = ({ type, subtype, name }) =>
  `${getTokenNamePrefix(type, subtype)}${name}`;
export const isReferenceToken = ({ reference }) => !!reference;
export const getAvailableReferences = (token, tokens) =>
  tokens.filter(
    item =>
      item.type === token.type &&
      !isReferenceToken(item) &&
      item.id !== token.id
  );

/**
 * Use collection from unstated next content if no token collection specified
 * @param id
 * @param data
 * @returns {*|{}}
 */
export const getTokenFromCurrentCollection = (
  id,
  data = CollectionStore.useContainer()[0].tokens
) => {
  return data.find(token => token.id === id) || {};
};

/**
 * Check that name is not used in other tokens
 * @param {*} token
 * @param {*} data
 */
export const isNameAvailable = (
  { id, type, subtype, name },
  data = CollectionStore.useContainer()[0].tokens
) =>
  !data.find(
    token =>
      token.name === name &&
      token.type === type &&
      token.subtype === subtype &&
      token.id !== id
  );

/**
 * Check
 * @param id tokenId
 * @param {*} data
 */
export const getAmountOfTokenReferences = (
  id,
  data = CollectionStore.useContainer()[0].tokens
) =>
  data.reduce((acc, token) => {
    if (token.reference === id) {
      acc++;
    }
    return acc;
  }, 0);
