import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { increase } from '../../services/collectionVersioningService';

function ReleaseDialog({ onClose, onConfirm, isOpen, collection }) {
  if (!collection) {
    return <React.Fragment />;
  }

  const newVersion = increase(collection.version);
  const createRelease = () => onConfirm(newVersion);
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Create release'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Creating a release will bump collection version and create a new NPM
          repository package. It's possible for release to take few minutes
          before the package is available. <br />
          <br />
          Installation guide can be found in <b>Installation</b> tab.
          <br /> <br />
          <b>Latest version: {collection.version || 'unreleased'}</b>
          <br />
          <b>New version: {newVersion}</b>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color={'secondary'} onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={createRelease} autoFocus>
          Release
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ReleaseDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  collection: PropTypes.object
};

export default ReleaseDialog;
