import React from 'react';
import PropTypes from 'prop-types';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TokenIcon from '@material-ui/icons/GridOn';
import VersionIcon from '@material-ui/icons/Cached';
import UserIcon from '@material-ui/icons/People';
import CodeIcon from '@material-ui/icons/Code';
import TokenList from '../../../components/Tokenlist/TokenList';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography/Typography';

import VersionTab from './VersionTab';
import UserTab from './UserTab';
import InstallationTab from './InstallationTab';

const useStyles = makeStyles({
  tabsContainer: {
    borderBottom: '1px solid #e0e0e0'
  },
  tabIcon: {
    fontSize: '1rem',
    marginRight: '6px',
    marginBottom: '0 !important'
  },
  tabWrapper: {
    flexDirection: 'row'
  },
  tabLabelIcon: {
    minHeight: '58px'
  }
});

const CollectionTabs = ({
  handleTokenEdit,
  handleOpenTokenDeleteDialog,
  data
}) => {
  const [tabValue, setTabValue] = React.useState(0);
  const classes = useStyles();

  const handleTabChange = (event, newTabValue) => {
    setTabValue(newTabValue);
  };

  return (
    <>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="simple tabs example"
        indicatorColor="primary"
        textColor="primary"
        className={classes.tabsContainer}
      >
        <Tab
          label="Tokens"
          classes={{
            wrapper: classes.tabWrapper,
            labelIcon: classes.tabLabelIcon
          }}
          icon={<TokenIcon className={classes.tabIcon} />}
        />
        <Tab
          label="Versions"
          classes={{
            wrapper: classes.tabWrapper,
            labelIcon: classes.tabLabelIcon
          }}
          icon={<VersionIcon className={classes.tabIcon} />}
        />
        <Tab
          label="Users"
          classes={{
            wrapper: classes.tabWrapper,
            labelIcon: classes.tabLabelIcon
          }}
          icon={<UserIcon className={classes.tabIcon} />}
        />
        <Tab
          label="Installation"
          classes={{
            wrapper: classes.tabWrapper,
            labelIcon: classes.tabLabelIcon
          }}
          icon={<CodeIcon className={classes.tabIcon} />}
        />
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        <TokenList
          handleTokenEdit={handleTokenEdit}
          handleTokenDelete={handleOpenTokenDeleteDialog}
          tokens={data.tokens}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <VersionTab />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <UserTab />
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <InstallationTab packageId={data.packageId} version={data.version} />
      </TabPanel>
    </>
  );
};

CollectionTabs.propTypes = {
  handleTokenEdit: PropTypes.func.isRequired,
  handleOpenTokenDeleteDialog: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
};

const TabPanel = ({ children, value, index, ...other }) => {
  const tabContentStyle = { marginTop: '24px' };

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <div style={tabContentStyle}>{children}</div>}
    </Typography>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

export default CollectionTabs;
