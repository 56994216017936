import React, { Component } from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import Analytics from 'react-router-ga';
import { SnackbarProvider } from 'notistack';

import * as ROUTES from './constants/routes';

import NavBar from './components/NavBar/NavBar';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import AuthStateHandler from './components/AuthStateHandler/AuthStateHandler';
import DashBoard from './views/Dashboard/DashBoard';
import Editor from './views/Collection/Overview';
import Login from './views/Login/Login';
import Preview from './views/Preview/Preview';

import CollectionStore from './custom-hooks/CollectionStore';

import themeConfig from './theme/theme';
import IconButton from '@material-ui/core/IconButton';
import GlobalErrorBoundary from './components/GlobalErrorBoundary/GlobalErrorBoundary';

const theme = createMuiTheme(themeConfig);

class App extends Component {
  render() {
    const notistackRef = React.createRef();
    const onClickDismiss = key => () => {
      notistackRef.current.closeSnackbar(key);
    };

    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Analytics id="UA-142852538-1">
            <CollectionStore.Provider>
              <AuthStateHandler />
              <NavBar />
              <SnackbarProvider
                ref={notistackRef}
                action={key => (
                  <IconButton onClick={onClickDismiss(key)}>
                    <CloseIcon style={{ color: 'white' }} />
                  </IconButton>
                )}
                maxSnack={3}
              >
                <GlobalErrorBoundary>
                  <Switch>
                    <ProtectedRoute
                      path={ROUTES.DASHBOARD}
                      component={DashBoard}
                    />
                    <ProtectedRoute path={ROUTES.EDITOR} component={Editor} />
                    <Route path={ROUTES.LOGIN} component={Login} />
                    <Route path={ROUTES.PREVIEW} component={Preview} />
                    <Route component={Login} />
                  </Switch>
                </GlobalErrorBoundary>
              </SnackbarProvider>
            </CollectionStore.Provider>
          </Analytics>
        </Router>
      </MuiThemeProvider>
    );
  }
}

export default App;
