import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    top: 0,
    right: 0
  }
});

const CollectionMenu = ({
  id,
  handleEditDetails,
  handleClone,
  handleDelete
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();

  const handleTokenMenuClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <IconButton onClick={e => handleTokenMenuClick(e)}>
        <MoreVertIcon id="action-menu" />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem
          id={'menu-details'}
          onClick={() => {
            handleClose();
            handleEditDetails(id);
          }}
        >
          Name and description
        </MenuItem>
        <MenuItem
          disabled={true}
          id={'menu-clone'}
          onClick={() => {
            handleClose();
            handleClone(id);
          }}
        >
          Clone
        </MenuItem>
        <MenuItem
          id={'menu-delete'}
          onClick={() => {
            handleClose();
            handleDelete(id);
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </div>
  );
};

CollectionMenu.propTypes = {
  id: PropTypes.string.isRequired,
  handleEditDetails: PropTypes.func.isRequired,
  handleClone: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired
};

export default CollectionMenu;
