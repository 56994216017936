import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const TypeSelector = props => {
  const { types, current, handleTypeChange } = props;
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Type
      </Typography>
      {types.map(({ type, name }, index) => (
        <Button
          variant={current === type ? 'contained' : 'text'}
          style={{ justifyContent: 'left' }}
          fullWidth={true}
          key={`${type}_${index}`}
          value={type}
          onClick={() => handleTypeChange(type)}
        >
          {name}
        </Button>
      ))}
    </>
  );
};

TypeSelector.propTypes = {
  types: PropTypes.array.isRequired,
  current: PropTypes.string,
  handleTypeChange: PropTypes.func.isRequired
};

export default TypeSelector;
