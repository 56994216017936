import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    width: "100%",
    maxWidth: 500
  },
  mainTitle: {
    fontSize: "32px"
  },
  spanH3: {
    fontSize: "18px",
    display: "block",
    marginBottom: "10px"
  },
  bodyBlock: {
    display: "block",
    fontSize: "14px",
    marginBottom: "15px"
  },
  spanul: {
    display: "block",
    marginBottom: "10px"
  },
  spanli: {
    display: "block",
    marginBottom: "5px",
    fontSize: "14px"
  },
  spanBullet: {
    paddingLeft: "3px",
    paddingRight: "3px",
    fontSize: "14px"
  }
});

export default function Privacy() {
  const classes = useStyles();
  return (
    <span className={classes.root}>
      <span className={classes.mainTitle}>designtokens.dev Privacy Policy</span>
      <span className={classes.bodyBlock}>
        This privacy policy will explain how our organization uses the personal
        data we collect from you when you use our web application.
      </span>
      <span className={classes.bodyBlock}>Topics:</span>
      <span className={classes.spanul}>
        <span className={classes.spanli}>
          <span className={classes.spanBullet}>-</span>What data do we collect?
        </span>
        <span className={classes.spanli}>
          <span className={classes.spanBullet}>-</span>How do we collect your
          data?
        </span>
        <span className={classes.spanli}>
          <span className={classes.spanBullet}>-</span>How will we use your
          data?
        </span>
        <span className={classes.spanli}>
          <span className={classes.spanBullet}>-</span>How do we store your
          data?
        </span>
        <span className={classes.spanli}>
          <span className={classes.spanBullet}>-</span>Marketing
        </span>
        <span className={classes.spanli}>
          <span className={classes.spanBullet}>-</span>What are your data
          protection rights?
        </span>
        <span className={classes.spanli}>
          <span className={classes.spanBullet}>-</span>What are cookies?
        </span>
        <span className={classes.spanli}>
          <span className={classes.spanBullet}>-</span>How do we use cookies?
        </span>
        <span className={classes.spanli}>
          <span className={classes.spanBullet}>-</span>What types of cookies do
          we use?
        </span>
        <span className={classes.spanli}>
          <span className={classes.spanBullet}>-</span>How to manage your
          cookies?
        </span>
        <span className={classes.spanli}>
          <span className={classes.spanBullet}>-</span>Privacy policies of other
          websites
        </span>
        <span className={classes.spanli}>
          <span className={classes.spanBullet}>-</span>Changes to our privacy
          policy
        </span>
        <span className={classes.spanli}>
          <span className={classes.spanBullet}>-</span>How to contact us
        </span>
        <span className={classes.spanli}>
          <span className={classes.spanBullet}>-</span>How to contact the
          appropriate authorities
        </span>
      </span>
      <span className={classes.spanH3}>What data do we collect?</span>
      <span className={classes.bodyBlock}>
        designtokens.dev collects the following data:
      </span>
      <span className={classes.spanul}>
        <span className={classes.spanli}>
          <span className={classes.spanBullet}>-</span>Personal identification
          information (Name, email address)
        </span>
      </span>
      <span className={classes.spanH3}>How do we collect your data?</span>
      <span className={classes.bodyBlock}>
        You directly provide designtokens.dev with all of the data we collect.
        We collect data and process data when you:
      </span>
      <span className={classes.spanul}>
        <span className={classes.spanli}>
          <span className={classes.spanBullet}>-</span>Sign up to our demo
          application
        </span>
        <span className={classes.spanli}>
          <span className={classes.spanBullet}>-</span>Voluntarily complete a
          customer survey or provide feedback on any of our message boards or
          via email.
        </span>
        <span className={classes.spanli}>
          <span className={classes.spanBullet}>-</span>Use or view our website
          via your browsers's cookies.
        </span>
      </span>
      <span className={classes.spanH3}>How will we use your data?</span>
      <span className={classes.bodyBlock}>
        designtokens.dev collects your data so that we can:
      </span>
      <span className={classes.spanul}>
        <span className={classes.spanli}>
          <span className={classes.spanBullet}>-</span>Manage your account.
        </span>
        <span className={classes.spanli}>
          <span className={classes.spanBullet}>-</span>Email you product
          updates.
        </span>
      </span>
      <span className={classes.spanH3}>How do we store your data?</span>
      <span className={classes.bodyBlock}>
        designtokens.dev securely stores your data at US
      </span>
      <span className={classes.bodyBlock}>
        designtokens.dev will keep your personal identification information as
        long as your accont exists. We will delete your data by when you remove
        your user account.
      </span>
      <span className={classes.spanH3}>Marketing</span>
      <span className={classes.bodyBlock}>
        designtokens.dev would like to send you information about product
        updates.
      </span>
      <span className={classes.bodyBlock}>
        You have the right at any time to stop designtokens.dev from contacting
        you for marketing purposes.
      </span>
      <span className={classes.bodyBlock}>
        If you no longer wish to be contacted for marketing purposes, send us
        email: contact@designtokens.dev
      </span>
      <span className={classes.spanH3}>
        What are your data protection rights?
      </span>
      <span className={classes.bodyBlock}>
        designtokens.dev would like to make sure you are fully aware of all of
        your data protection rights. Every user is entitled to the following:
      </span>
      <span className={classes.bodyBlock}>
        The right to access - You have the right to request designtokens.dev for
        copies of your personal data. We may charge you a small fee for this
        service.
      </span>
      <span className={classes.bodyBlock}>
        The right to rectification - You have the right to rectification - You
        have the right to request that designtokens.dev correct any information
        you believe is inaccurate. You also have the right to request
        designtokens.dev to complete information you believe is incomplete.
      </span>
      <span className={classes.bodyBlock}>
        The right to erasure - You have the right to request that
        designtokens.dev erase your personal data, under certain conditions.
      </span>
      <span className={classes.bodyBlock}>
        This right to restrict processing - You have the right to object to
        designtokens.dev processing of your personal data, under certain
        conditions.
      </span>
      <span className={classes.bodyBlock}>
        If you make a request, we have two months to respond to you. If you
        would like to exercise any of these rights, please contact us at our
        email:
      </span>
      <span className={classes.bodyBlock}>contact@designtokens.dev</span>

      <span className={classes.spanH3}>What are cookies?</span>
      <span className={classes.bodyBlock}>
        Cookies are text files placed on your computer to collect standard
        Interet log information and visitor behavior information. When you visit
        our websites, we may collect information from you automatically through
        cookies or similar technology.
      </span>
      <span className={classes.bodyBlock}>
        For further information, visit{" "}
        <a
          href="http://allaboutcookies.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          allaboutcookies.org
        </a>
      </span>
      <span className={classes.spanH3}>How do we use cookies?</span>
      <span className={classes.bodyBlock}>
        designtokens.dev uses cookies in a range of ways to improve your
        experience on our website, including:
      </span>
      <span className={classes.spanul}>
        <span className={classes.spanli}>
          <span className={classes.spanBullet}>-</span>Keeping you signed in
        </span>
        <span className={classes.spanli}>
          <span className={classes.spanBullet}>-</span>Understanding how you use
          our website
        </span>
      </span>
      <span className={classes.spanH3}>What types of cookies do we use?</span>
      <span className={classes.bodyBlock}>
        There are a number of different types of cookies, however, our websites
        uses:
      </span>
      <span className={classes.spanul}>
        <span className={classes.spanli}>
          <span className={classes.spanBullet}>-</span>Functionality -
          designtokens.dev uses these cookies so that we recognize you on our
          website and remember your previously selected preferences. These could
          include what location you are in. A mix of first-party and third-party
          cookies are used.
        </span>
        <span className={classes.spanli}>
          <span className={classes.spanBullet}>-</span>Advertising -
          designtokens.dev uses these cookies to collect information about your
          visit to our website, the content you viewed, the links you followed
          and information about your browser, device and your IP address. We may
          share online data collected through cookies with our advertising
          partners. This means that when you visit another website, you may me
          shown advertising based on your browsing patterns on our website.
        </span>
      </span>
      <span className={classes.spanH3}>How to manage cookies</span>
      <span className={classes.bodyBlock}>
        You can set your browser not to accept cookies, and the above website
        tells you how to remove cookies from your browser. However, in a few
        cases some our website features may not function as a result.
      </span>
      <span className={classes.spanH3}>Privacy policy of other websites</span>
      <span className={classes.bodyBlock}>
        The designtokens.dev website may contain links to other websites. Our
        privacy policy applies only to our website, so if you click on a link to
        another website, you should read their privacy policy.
      </span>
      <span className={classes.spanH3}>Changes to our privacy policy</span>
      <span className={classes.bodyBlock}>
        designtokens.dev keeps its privacy policy under regular review and
        places any updates on this web page. This privacy policy was last
        updated on 6.9.2019.
      </span>
      <span className={classes.spanH3}>How to contact us</span>
      <span className={classes.bodyBlock}>
        If you have any questions about designtokens.dev privacy policy, the
        data we hold on you, or you would like to exercise one of your data
        protection rights, please do not hesitate to contact us.
      </span>
      <span className={classes.bodyBlock}>contact@designtokens.dev</span>
    </span>
  );
}
