import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  box: {
    border: '1px solid',
    borderRadius: '2px',
    padding: theme.spacing(0.5, 1),
    fontSize: '10px'
  },
  red: {
    borderColor: red[600],
    color: red[600]
  },
  green: {
    borderColor: green[600],
    color: green[600]
  }
}));

const VersionBadge = ({ version }) => {
  const classes = useStyles();
  return (
    <Typography variant="overline" gutterBottom>
      {version ? (
        <span className={classNames(classes.box, classes.green)}>
          {version}
        </span>
      ) : (
        <span className={classNames(classes.box, classes.red)}>
          not released
        </span>
      )}
    </Typography>
  );
};

VersionBadge.propTypes = {
  version: PropTypes.string
};

export default VersionBadge;
