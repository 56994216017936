import React from 'react';
import PropTypes from "prop-types";

/**
 * Line height token preview
 * Use TokenPreview to autodetect type
 * @param props
 * @returns {*}
 * @constructor
 */
function LineHeightPreview(props) {
  const {value} = props;
  return (<div style={{'lineHeight': value + 'px'}}><p>The quick brown fox<br/> jumps over the lazy dog</p></div>);
}

LineHeightPreview.propTypes = {
  value: PropTypes.number
};

export default LineHeightPreview;