import ColorPicker from '../components/TokenPicker/ColorPicker';
import ColorPreview from '../components/TokenPreview/ColorPreview';
import DefaultPicker from '../components/TokenPicker/DefaultPicker';
import SliderPicker from '../components/TokenPicker/SliderPicker';
import DefaultPreview from '../components/TokenPreview/DefaultPreview';
import RadiusPreview from '../components/TokenPreview/RadiusPreview';
import OpacityPreview from '../components/TokenPreview/OpacityPreview';

const TokenTypes = [
  {
    type: 'color',
    name: 'Color',
    description:
      'Color tokens are divided into subcategories. Create values first and use those as a reference when creating other design tokens. That gives you an easy way to update token values later.',
    defaultValue: { r: 255, g: 0, b: 0, a: 1 },
    subtypes: [
      {
        type: 'palette',
        default: true,
        name: 'Palette',
        picker: ColorPicker,
        preview: ColorPreview
      },
      {
        type: 'background',
        name: 'Background',
        picker: ColorPicker,
        preview: ColorPreview
      },
      {
        type: 'text',
        name: 'Text',
        picker: ColorPicker,
        preview: ColorPreview
      },
      {
        type: 'border',
        name: 'Border',
        picker: ColorPicker,
        preview: ColorPreview
      }
    ]
  },
  {
    type: 'elevation',
    name: 'Elevation & shadow',
    description:
      'Elevation is the distance between any two elements along the z-axis. These tokens determine the size of a drop shadow (or box-shadow) around an item.',
    defaultValue: '',
    picker: DefaultPicker,
    preview: DefaultPreview
  },
  {
    type: 'font',
    name: 'Font',
    description:
      'Your font tokens. Create values first and use those as a reference when creating other design tokens. That gives you an easy way to update token values later. Use subcategories like family, size, weight, line height, or letter-spacing.',
    defaultValue: '',
    picker: DefaultPicker,
    preview: DefaultPreview
  },
  {
    type: 'height',
    name: 'Height',
    description:
      'Use this token type for defining a line-height or height of an element.',
    defaultValue: '',
    picker: DefaultPicker,
    preview: DefaultPreview
  },
  {
    type: 'opacity',
    name: 'Opacity',
    description: 'Use opacity token type for element transparency.',
    defaultValue: 10,
    picker: SliderPicker,
    preview: OpacityPreview
  },
  {
    type: 'radius',
    name: 'Radius',
    description:
      'Use radius token type for element radius, e.g., border-radius size.',
    defaultValue: '5px',
    picker: DefaultPicker,
    preview: RadiusPreview
  },
  {
    type: 'size',
    name: 'Size',
    description: 'Use this token type for defining your size scale.',
    defaultValue: '',
    picker: DefaultPicker,
    preview: DefaultPreview
  },
  {
    type: 'space',
    name: 'Space',
    description:
      'Your spacing tokens divided into subcategories. Create values first and use those as a reference when creating other design tokens. That gives you an easy way to update token values later.',
    defaultValue: '',
    subtypes: [
      {
        default: true,
        type: 'inline',
        name: 'Inline',
        picker: DefaultPicker,
        preview: DefaultPreview
      },
      {
        type: 'inset',
        name: 'Inset',
        picker: DefaultPicker,
        preview: DefaultPreview
      },
      {
        type: 'stack',
        name: 'Stack',
        picker: DefaultPicker,
        preview: DefaultPreview
      }
    ]
  },
  {
    type: 'misc',
    name: 'Miscellaneous',
    description:
      "Use this token type for tokens that don't fit into any categories.",
    defaultValue: '',
    picker: DefaultPicker,
    preview: DefaultPreview
  }
];

export default TokenTypes;
