import download from 'downloadjs';

export const exportCollection = async (collection, format) => {
  const urlNPM =
    process.env.REACT_APP_DEV_CLOUD_FUNCTIONS_DOWNLOAD_AS_NPM_PACKAGE;
  const urlJSON = process.env.REACT_APP_DEV_CLOUD_FUNCTIONS_DOWNLOAD_AS_JSON;

  const response = await fetch(format === 'JSON' ? urlJSON : urlNPM, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, cors, *same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json'
    },
    redirect: 'follow', // manual, *follow, error
    referrer: 'no-referrer', // no-referrer, *client
    body: JSON.stringify(collection) // body data type must match "Content-Type" header
  });

  const data = await response.blob();
  if (format === 'JSON') {
    return download(data, 'tokens.json', 'application/json');
  } else {
    return download(data, 'tokens.tgz', 'application/gzip');
  }
};
