import React from 'react';
import PropTypes from 'prop-types';

import ColorPicker from './ColorPicker';
import DefaultPicker from './DefaultPicker';
import SliderPicker from './SliderPicker';

/**
 * Render picker component for token based on type
 * @param type
 * @param onChange
 * @param value
 * @returns {*}
 * @constructor
 */
const TokenValuePicker = ({ type, onChange, value }) => {
  switch (type) {
    case 'color':
      return (
        <ColorPicker
          value={value || '#fff'}
          onChange={value => onChange(value.rgb)}
        />
      );
    case 'opacity':
      return (
        <SliderPicker
          label={'Opacity'}
          minValue={0}
          maxValue={100}
          value={value}
          onChange={value => onChange(value)}
          unit={'%'}
        />
      );
    default:
      return <DefaultPicker value={value} onChange={onChange} />;
  }
};

TokenValuePicker.propTypes = {
  type: PropTypes.oneOf([
    'color',
    'default',
    'opacity',
    'radius',
    'height',
    'spacing',
    'misc'
  ]),
  value: PropTypes.any,
  onChange: PropTypes.func
};

TokenValuePicker.defaultProps = {
  type: 'default'
};

export default TokenValuePicker;
