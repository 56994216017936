/**
 * Versioning service follows npm semantic versioning rules described at
 * https://docs.npmjs.com/about-semantic-versioning
 */

/**
 * Increase collection version by 0.0.1
 * TODO: Determine real increase by comparing previous and new version
 * @param collection
 * @returns {*}
 */
export const increase = (version, increase = 'patch') => {
  const initialVersion = '0.0.1';

  try {
    const versionSplit = version.split('.').map(entry => parseInt(entry));

    switch (increase) {
      case 'patch':
        versionSplit[2]++;
        break;
      case 'minor':
        versionSplit[1]++;
        versionSplit[2] = 0;
        break;
      case 'major':
        versionSplit[0]++;
        versionSplit[1] = 0;
        versionSplit[2] = 0;
        break;
    }
    return `${versionSplit[0]}.${versionSplit[1]}.${versionSplit[2]}`;
  } catch (e) {
    return initialVersion;
  }
};
