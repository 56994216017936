import React from 'react';
import PropTypes from 'prop-types';
import { TextValidator } from 'react-material-ui-form-validator';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import FormControl from '@material-ui/core/FormControl/FormControl';
import { withStyles } from '@material-ui/core';
import { getTokenFullName } from '../../utils/utils';

/**
 * Reference field picker
 * @param onChange
 * @param value
 * @returns {*}
 * @constructor
 */
const ReferencePicker = ({ reference, items, onChange, classes }) => {
  /**
   * Find target token by id and assign value and reference to onChange callback
   * @param event
   * @returns {*}
   */

  if (!reference) {
    reference = '';
  }

  const handleChange = event => {
    if (event.target) {
      const referenceTokenId = event.target.value;
      const target = items.find(item => item.id === referenceTokenId);
      onChange({ reference: target.id, value: target.value });
    }
  };

  return (
    <FormControl
      fullWidth={true}
      variant="outlined"
      className={classes.formControl}
    >
      <TextValidator
        id="outlined-select-currency"
        select
        variant="outlined"
        label="Reference"
        value={reference}
        onChange={handleChange}
        validators={['minStringLength:1']}
        errorMessages={['reference is required']}
        SelectProps={{
          MenuProps: {
            className: classes.menu
          }
        }}
      >
        {items.map((token, index) => (
          <MenuItem key={`${token.name}_${index}`} value={token.id}>
            {getTokenFullName(token)}
          </MenuItem>
        ))}
      </TextValidator>
    </FormControl>
  );
};

const styles = theme => ({
  formControl: {
    marginTop: theme.spacing(3)
  }
});

ReferencePicker.propTypes = {
  reference: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
      reference: PropTypes.string
    })
  )
};

export default withStyles(styles)(ReferencePicker);
