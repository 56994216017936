import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import uuidv4 from 'uuid/v4';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Slide from '@material-ui/core/Slide';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';

import tokenTypes from '../../../constants/tokenTypes';
import { getDefaultSubtype, getTokenNamePrefix } from '../../../utils/utils';
import TokenPicker from '../../../components/TokenPicker/TokenPicker';
import TokenPreview from '../../../components/TokenPreview/TokenPreview';
import TypeSelector from './TypeSelector';
import SubtypeSelector from './SubtypeSelector';

const styles = theme => ({
  root: {
    display: 'flex',
    padding: theme.spacing(2)
  },
  appBar: {
    position: 'sticky'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  typeDesc: {
    marginBottom: theme.spacing(3)
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TokenDialog(props) {
  const defaultStatus = {
    type: 'color',
    subtype: 'palette',
    value: { r: 255, g: 0, b: 0, a: 1 },
    description: ''
  };
  const {
    isOpen,
    selectedToken,
    handleClose,
    handleSave,
    nameValidator,
    classes
  } = props;

  let typeData;
  const [data, setData] = useState(defaultStatus);

  const getTypeDate = type => tokenTypes.find(item => item.type === type) || {};

  const handleChange = name => event =>
    setData({ ...data, [name]: event.target.value });

  /**
   * Find default subtype & clear value
   * @param event
   */
  const handleTypeChange = type => {
    setData({
      ...data,
      value: getTypeDate(type).defaultValue,
      type,
      subtype: getDefaultSubtype(type),
      reference: null
    });
  };

  const handlePickerChange = ({ value, reference }) =>
    setData({ ...data, value, reference });

  const handleNew = () => {
    handleSave(data);
    handleClose();
  };

  useEffect(() => {
    ValidatorForm.addValidationRule('isUniqueName', value =>
      nameValidator({ ...data, name: value })
    );
    return function cleanup() {
      ValidatorForm.removeValidationRule('isUniqueName');
    };
  });

  useEffect(() => {
    if (selectedToken.id) setData({ ...selectedToken });
    else setData({ ...defaultStatus, id: uuidv4() });
  }, [selectedToken]);

  typeData = getTypeDate(data.type);

  return (
    <Dialog
      fullScreen={true}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      TransitionComponent={Transition}
    >
      <ValidatorForm onSubmit={handleNew}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              id={'close-button'}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Token editor
            </Typography>
            <Button autoFocus color="inherit" type={'submit'}>
              save
            </Button>
          </Toolbar>
        </AppBar>
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <TypeSelector
                types={tokenTypes}
                handleTypeChange={handleTypeChange}
                current={data.type}
              />
            </Grid>
            <Grid item xs={9}>
              <Grid container spacing={3} direction={'column'}>
                <Grid item>
                  <Typography variant="h4" gutterBottom>
                    {typeData.name}
                  </Typography>
                  <Typography
                    variant="body1"
                    gutterBottom
                    className={classes.typeDesc}
                  >
                    {typeData.description}
                  </Typography>
                  {typeData.subtypes && (
                    <>
                      <Typography variant="h6" gutterBottom>
                        Subtype
                      </Typography>
                      <Grid container spacing={1} direction={'row'}>
                        <SubtypeSelector
                          type={data.type}
                          subtype={data.subtype}
                          handleChange={handleChange}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>

                <Grid item>
                  <Typography variant="h6" gutterBottom>
                    Details
                  </Typography>
                  <Grid container direction={'row'} spacing={1}>
                    <Grid item xs={12} md={4}>
                      <TextValidator
                        id="name-input"
                        label="Name"
                        fullWidth={true}
                        validators={['isUniqueName', 'minStringLength:1']}
                        errorMessages={[
                          'Please provide unique name',
                          'name field is required'
                        ]}
                        value={data.name}
                        margin="normal"
                        variant="outlined"
                        onChange={handleChange('name')}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {getTokenNamePrefix(data.type, data.subtype)}
                            </InputAdornment>
                          )
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TextValidator
                        id="descriptin-input"
                        label="Description (optional)"
                        fullWidth={true}
                        multiline
                        rowsMax="4"
                        value={data.description}
                        margin="normal"
                        variant="outlined"
                        onChange={handleChange('description')}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Typography variant="h6" gutterBottom>
                    Value
                  </Typography>
                  <Grid container direction={'column'} spacing={1}>
                    <Grid item>
                      <TokenPicker
                        token={data}
                        tokens={props.tokens}
                        onChange={value => handlePickerChange(value)}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Typography variant="h6" gutterBottom>
                    Preview
                  </Typography>
                  <Grid item>
                    <TokenPreview token={data} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </ValidatorForm>
    </Dialog>
  );
}

TokenDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  selectedToken: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  nameValidator: PropTypes.func.isRequired,
  tokens: PropTypes.array.isRequired
};

export default withStyles(styles)(TokenDialog);
