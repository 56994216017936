import React, {useEffect, useState} from 'react';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import {useSnackbar} from 'notistack';

import CollectionStore from '../../custom-hooks/CollectionStore';

import TokenList from "../../components/Tokenlist/TokenList";

import {downloadPreviewData} from "../../services/collectionPreviewService";
import Placeholder from "../../components/Placeholder/Placeholder";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    minHeight: '100vh',
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary
  }
});

function Preview(props) {
  const {classes, match} = props;
  const ownerId = match.params.owner;
  const workspaceId = match.params.workspace;
  const {enqueueSnackbar} = useSnackbar();
  const [data, setData] = CollectionStore.useContainer();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
      async function fetchData() {
        try {
          const collection = await downloadPreviewData({ownerId, workspaceId});
          setLoading(false);
          setData(collection);
        } catch (error) {
          enqueueSnackbar('Error loading document', {variant: 'error'})
        } finally {
          setLoading(false);
        }
      }

      fetchData();
    }
    , []);

  const version = data.version ? <>(v. {data.version})</> : null;
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container spacing={2} direction={"row"}>
          {isLoading ?
            <Placeholder type={"collection"}/>
            :
            (
              <>
                <Grid item xs={12}>
                  <Typography variant="h3" gutterBottom>
                    {data.name} {version}
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    {data.description}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <TokenList tokens={data.tokens}/>
                </Grid>
              </>)}
        </Grid>
      </Paper>
    </div>);
}

export default withStyles(styles)(Preview);