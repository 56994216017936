import React, { useContext } from 'react';
import { compose } from 'recompose';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid/Grid';

import Button from '@material-ui/core/Button';
import { exportCollection } from '../../services/collectionDownloadService';
import firebaseContext from '../../firebase/context';
import { getPreviewLink } from '../../services/collectionPreviewService';
import PublishIcon from '@material-ui/icons/Publish';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import PreviewIcon from '@material-ui/icons/Visibility';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import VersionBadge from '../VersionBadge/VersionBadge';

const styles = theme => ({
  header: {
    color: theme.palette.text.primary
  },
  description: {
    color: theme.palette.text.primary
  },
  jsonButton: {
    marginRight: theme.spacing(1)
  },
  btnIcon: {
    marginRight: theme.spacing(1)
  },
  btnWrapper: {
    marginTop: theme.spacing(1.25)
  },
  btnInfo: {
    marginTop: theme.spacing(1),
    textAlign: 'left'
  },
  previewLinkArea: {
    marginTop: theme.spacing(2)
  },
  previewText: {
    verticalAlign: 'super'
  }
});

function VersionInfo(props) {
  const { data, classes, toggleReleaseDialog, handleCopyToClipboard } = props;
  const firebase = useContext(firebaseContext);
  const [loading, setLoading] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleDownloadClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleDownloadClose() {
    setAnchorEl(null);
  }

  const createExport = async selection => {
    handleDownloadClose();
    try {
      setLoading(true);
      await exportCollection(data, selection);
    } catch (err) {
      alert('Failed to load collection');
    } finally {
      setLoading(false);
    }
  };

  const previewLink = getPreviewLink(firebase.auth.currentUser?.uid, data.id);

  return (
    <>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid item xs={12} md={7}>
          <Typography
            variant="h6"
            component="h1"
            className={classes.header}
            id="collection-name"
            gutterBottom
          >
            {data.name}
          </Typography>
          <Typography
            variant="body1"
            className={classes.description}
            id="collection-description"
            gutterBottom
          >
            {data.description}
          </Typography>
          <VersionBadge version={data.version} />
          <Typography
            variant="subtitle2"
            className={classes.previewLinkArea}
            gutterBottom
          >
            <PreviewIcon className={classes.btnIcon} />
            <span className={classes.previewText}>Preview page</span>
          </Typography>
          <Typography variant="body1" gutterBottom>
            <Button
              id="preview-button"
              color="primary"
              size="small"
              href={previewLink}
            >
              Open Preview
            </Button>
            <CopyToClipboard text={previewLink} onCopy={handleCopyToClipboard}>
              <Button color="primary" size="small">
                Copy Link
              </Button>
            </CopyToClipboard>
          </Typography>
        </Grid>

        <Grid item xs={12} md={3} className={classes.btnWrapper}>
          <Button
            id="download-button"
            variant="outlined"
            disabled={loading}
            className={classes.button}
            color="primary"
            fullWidth={true}
            size="large"
            onClick={handleDownloadClick}
          >
            <DownloadIcon className={classes.btnIcon} /> Download
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleDownloadClose}
          >
            <MenuItem onClick={() => createExport('JSON')}>JSON</MenuItem>
            <MenuItem onClick={() => createExport('NPM')}>NPM package</MenuItem>
          </Menu>
          <div className={classes.btnInfo}>
            Download latest changes as JSON or NPM Package
          </div>
        </Grid>
        <Grid item xs={12} md={2} align="right" className={classes.btnWrapper}>
          <Button
            id="release-button"
            variant="contained"
            color="primary"
            className={classes.button}
            fullWidth={true}
            size="large"
            onClick={toggleReleaseDialog}
          >
            <PublishIcon className={classes.btnIcon} /> Publish
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

VersionInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  toggleReleaseDialog: PropTypes.func.isRequired,
  handleCopyToClipboard: PropTypes.func,
  data: PropTypes.object
};

export default compose(withStyles(styles))(VersionInfo);
