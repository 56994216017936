const downloadPreviewData = async queryParams => {
  const url = new URL(process.env.REACT_APP_DEV_CLOUD_FUNCTIONS_GET_PREVIEW);
  url.search = new URLSearchParams(queryParams);
  try {
    const response = await fetch(url);
    const collection = await response.json();
    return collection;
  } catch (error) {
    throw error;
  }
};

const getPreviewLink = (ownerId, collectionId) => {
  return `${process.env.REACT_APP_DEV_PREVIEW_URL}/${ownerId}/${collectionId}`;
};

export { downloadPreviewData, getPreviewLink };
