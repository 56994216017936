import React from 'react';
import PropTypes from 'prop-types';
import { TextValidator } from 'react-material-ui-form-validator';

/**
 * Default token value picker
 * Use TokenValuePicker autodetect type
 * @param onChange
 * @param value
 * @returns {*}
 * @constructor
 */

const DefaultPicker = ({ onChange, value }) => {
  const handleChange = event => onChange(event.target.value);
  return (
    <TextValidator
      id="value picker"
      label="Value"
      value={value}
      margin="normal"
      variant="outlined"
      onChange={handleChange}
      validators={['minStringLength:1']}
      errorMessages={['value is required']}
    />
  );
};

DefaultPicker.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default DefaultPicker;
