import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

function DeleteTokenDialog({ onClose, onConfirm, isOpen, token }) {
  const { amountOfReferences } = token || {};
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Delete token?'}</DialogTitle>
      <DialogContent>
        {amountOfReferences ? (
          <DialogContentText id="delete-forbidden">
            <span style={{ color: 'red' }}>
              This token is referenced by {amountOfReferences} other tokens. It
              can't be deleted before references are removed.
            </span>
          </DialogContentText>
        ) : (
          <DialogContentText id="delete-allowed">
            Deleting token will affect only upcoming versions of token package
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          disabled={amountOfReferences}
          onClick={onConfirm}
          color={'secondary'}
          autoFocus
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DeleteTokenDialog.propTypes = {
  token: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

export default DeleteTokenDialog;
