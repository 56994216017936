import PropTypes from "prop-types";
import React from 'react';


/**
 * Default token preview
 * Use TokenPreview to autodetect type
 * @param props
 * @returns {*}
 * @constructor
 */
const DefaultPreview = ({ value }) => {
  return value ? <span>{typeof (value) === 'string' ? value : JSON.stringify(value)}</span> : <React.Fragment />;
};

DefaultPreview.propTypes = {
  value: PropTypes.any
};

export default DefaultPreview;