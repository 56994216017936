import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button/Button';
import { withRouter } from 'react-router-dom';

import { withFirebase } from '../../firebase';
import PropTypes from 'prop-types';
import * as ROUTES from '../../constants/routes';

import PrivacyDialog from '../Privacy/PrivacyDialog';
import google from '../../assets/images/google.svg';
import { Divider } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
  loginContainer: {
    maxWidth: '400px',
    textAlign: 'center',
    margin: '100px auto'
  },
  loginTitle: {
    fontWeight: '500',
    fontSize: '18px',
    marginTop: '0',
    marginBottom: '0',
    marginLeft: '28px',
    textAlign: 'left',
    color: '#545454'
  },
  demoWarn: {
    textAlign: 'left',
    marginLeft: '28px',
    marginBottom: theme.spacing(4),
    lineHeight: '20px'
  },
  loginForm: {},
  button: {
    '&:hover': {
      backgroundColor: '#fff'
    },
    margin: theme.spacing.unit,
    backgroundColor: '#fff',
    direction: 'ltr',
    fontWeight: 500,
    height: 'auto',
    lineHeight: 'normal',
    maxWidth: '300px',
    minHeight: '40px',
    padding: '8px 16px',
    textAlign: 'center',
    width: '100%',
    background: '0 0',
    border: '2px solid #ccc',
    borderRadius: '2px',
    position: 'relative',
    minWidth: '64px',
    display: 'inline-block',
    fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
    fontSize: '14px',
    textTransform: 'uppercase',
    letterSpacing: '0',
    overflow: 'hidden',
    willChange: 'box-shadow',
    transition:
      'box-shadow .2s cubic-bezier(.4,0,1,1),background-color .2s cubic-bezier(.4,0,.2,1),color .2s cubic-bezier(.4,0,.2,1)',
    outline: '0',
    cursor: 'pointer',
    textDecoration: 'none',
    verticalAlign: 'middle',
    color: 'rgb(117, 117, 117)'
  },
  buttonText: {
    paddingLeft: '16px',
    fontSize: '14px',
    textTransform: 'none',
    verticalAlign: 'middle'
  },
  idpbutton: {
    display: 'inline-block',
    height: '18px',
    verticalAlign: 'middle',
    width: '18px',
    borderWidth: 'initial',
    borderStyle: 'none',
    borderColor: 'initial',
    borderImage: 'initial'
  },
  loginWrapper: {
    backgroundColor: '#fff',
    padding: '30px 20px 20px 20px',
    borderRadius: '3px',
    border: 'solid 1px #efefef',
    marginTop: '20px',
    boxShadow: 'rgba(0,0,0,0.1) 0 0 10px'
  },
  terms: {
    marginTop: '10px',
    color: '#888',
    width: '340px',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  privacyButton: {
    border: 'none',
    fontSize: '14px',
    color: '#0000ee',
    textDecoration: 'underline',
    cursor: 'pointer',
    marginTop: theme.spacing(2),
    padding: '0px',
    background: 'none'
  },
  bottomDivider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3)
  },
  bottomLinkList: {
    display: 'inline'
  },
  bottomLinkListFirst: {
    marginRight: theme.spacing(2)
  },
  emailInput: {
    width: '100%',
    maxWidth: '300px',
    marginBottom: theme.spacing(2)
  },
  emailLoginBtn: {
    width: '100%',
    maxWidth: '300px',
    marginBottom: theme.spacing(2)
  }
}));

const Login = ({ firebase, history }) => {
  const classes = useStyles();

  useEffect(() => {
    firebase.auth.onAuthStateChanged(user => {
      history.push(user ? ROUTES.DASHBOARD : ROUTES.LOGIN);
    });
  }, []);

  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [flow, setFlow] = useState('login');

  const openDialog = () => setOpen(true);

  const closeDialog = () => setOpen(false);

  const handleEmailChange = event => setEmail(event.target.value);

  const handlePasswordChange = event => setPassword(event.target.value);

  // Login redirect handled AuthStateHandler component
  const onGoogleLogin = event => {
    event.preventDefault();
    const { auth, googleAuthProvider } = firebase;
    auth
      .signInWithPopup(googleAuthProvider)
      .then(() => history.push(ROUTES.DASHBOARD))
      .catch(error => {
        setError(error);
      });
  };

  const onEmailLogin = event => {
    event.preventDefault();
    const { auth } = firebase;

    switch (flow) {
      default:
      case 'login':
        auth
          .signInWithEmailAndPassword(email, password)
          .then(() => history.push(ROUTES.DASHBOARD))
          .catch(error => {
            setError(error);
          });
        break;
      case 'signup':
        auth
          .createUserWithEmailAndPassword(email, password)
          .then(() => history.push(ROUTES.DASHBOARD))
          .catch(error => {
            setError(error);
          });
        break;
      case 'reset': {
        auth
          .sendPasswordResetEmail(email)
          .then(() => {
            setFlow('login');
            setError({ message: 'Check your email for instructions' });
          })
          .catch(error => {
            setError(error);
          });
      }
    }
  };
  return (
    <div className={classes.loginContainer}>
      <PrivacyDialog isOpen={open} handleClose={closeDialog} />
      <div className={classes.loginWrapper}>
        {flow === 'login' && (
          <h1 className={classes.loginTitle}>Log in to designtokens.dev</h1>
        )}
        {flow === 'signup' && (
          <h1 className={classes.loginTitle}>Sign up to designtokens.dev</h1>
        )}
        {flow === 'reset' && (
          <h1 className={classes.loginTitle}>Reset your password</h1>
        )}
        <Typography
          variant="overline"
          display="block"
          className={classes.demoWarn}
          gutterBottom
        >
          Beta
        </Typography>

        <form className={classes.emailLoginForm} onSubmit={onEmailLogin}>
          <TextField
            id="email-username"
            className={classes.emailInput}
            label="Username (email)"
            variant="outlined"
            onChange={handleEmailChange}
          />
          {flow !== 'reset' && (
            <TextField
              id="email-pw"
              className={classes.emailInput}
              label="Password"
              type="password"
              variant="outlined"
              onChange={handlePasswordChange}
            />
          )}
          <Button
            type={'submit'}
            variant="contained"
            color="primary"
            className={classes.emailLoginBtn}
            disableElevation
          >
            {flow === 'login' && <span>Log In</span>}
            {flow === 'signup' && <span>Sign Up</span>}
            {flow === 'reset' && <span>Reset</span>}
          </Button>
        </form>

        <Typography variant="overline" display="block" gutterBottom>
          Or
        </Typography>
        <form onSubmit={onGoogleLogin} className={classes.loginForm}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="small"
            className={classes.button}
            disableElevation
          >
            <span>
              <img className={classes.idpbutton} alt="" src={google} />
            </span>
            <span className={classes.buttonText}>Log In with Google</span>
          </Button>
          {error && <p>{error.message}</p>}
        </form>
        <Divider className={classes.bottomDivider} />
        <div className={classes.bottomLinkList}>
          {flow !== 'reset' && (
            <Link
              href="#"
              onClick={() => setFlow('reset')}
              className={classes.bottomLinkListFirst}
            >
              Can't log in?
            </Link>
          )}
          {flow !== 'signup' && (
            <Link href="#" onClick={() => setFlow('signup')}>
              Sign up for an account&nbsp;&nbsp;&nbsp;
            </Link>
          )}
          {flow !== 'login' && (
            <Link href="#" onClick={() => setFlow('login')}>
              Login with existing account
            </Link>
          )}
        </div>
      </div>

      <p className={classes.terms}>
        <button onClick={openDialog} className={classes.privacyButton}>
          Cookie &amp; Privacy policy
        </button>
      </p>
    </div>
  );
};

Login.propTypes = {
  firebase: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default withRouter(withFirebase(Login));
